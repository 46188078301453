import config from "config";
import routes from "root/routes";
import { isImage } from "./utils";

export const openVideo = videoUrl => {
  openURL(videoUrl);
};

export const openMap = (url, e) => {
  e?.preventDefault();
  e?.stopPropagation();
  openURL(url);
};

export const openURL = (url) => {
  window.open(url, "_blank");
};

export const previewDocument = (documentURL) => {
  if (isImage(documentURL)) {
    openURL(documentURL);
  } else {
    openURL(`https://docs.google.com/viewer?url=${documentURL}`);
  }
};

export const getBreacrumbStructuredData = (breadcrumbData) => {
  let BreadcrumbList = null;

  if (breadcrumbData) {
    BreadcrumbList = {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: breadcrumbData.map((item, index) => ({
        "@type": "ListItem",
        position: index + 1,
        item: {
          "@id":
            config.WEB_BASE_URL +
            routes.findAndGetUrls(item.route, item.params).urls.as,
          name: item.name
        }
      }))
    }
  }

  return BreadcrumbList;
};

export const isMobile = () => {
  if (navigator && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  }
  if (window && window.innerWidth < 600) {
    return true;
  }
  return false;
}

export const getBrand = () => "Browser";
