/* eslint-disable camelcase */
import React from "react";
import {
  Platform,
  StyleSheet,
  View,
  NativeModules,
  PermissionsAndroid,
  Alert,
  Linking
} from "react-native";
import S3Image from "screens/common/S3Image";
import {
  APPLICATION_HISTORY_ID,
  getSourceId,
  getTotalApplicationCount
} from "screens/common/utils/common";
import { buttonType as button_type } from "screens/common/constants";
import { SHORT_JOB_APPLY } from "screens/common/queries";
import SClickableArea from "styled42/components/SClickableArea";
import SButton from "styled42/components/SButton";
import Colors from "styled42/config/colors";
import {
  triggerAdjustApplicationEvent,
  triggerAdjustTrustedJobAppEvent,
  triggerAdjustOnApplicationCreationEvent
} from "utils/adjustEvents";
import {
  pushClevertapEvent,
  pushUTMEvent,
  pushClevertapProfile
} from "utils/clevertapEvents";
import { sendEventToGA } from "utils/googleAnalyticsEvents";
import getData from "utils/getData";
import { sendEventToGTM } from "utils/googleTagManagerEvents";
import { clientHelper } from "utils/graphQL";
import { openURL } from "utils/platformDependentUtils";
import { navigate } from "utils/routing/NavRouter";
import NavHistory from "utils/routing/NavHistory";
import { removeDataFromStorage, storeDataToStorage } from "utils/storage";
import { CallHrPhone } from "styled42/icons";

const sizeProps = {
  lg: {
    textProps: { size: 16, textContainerStyle: { paddingLeft: 7 } },
    imageHeight: 24,
    imageWidth: 24
  },
  sm: {
    textProps: { size: 12, textContainerStyle: { paddingLeft: 5 } },
    imageHeight: 16,
    imageWidth: 16
  }
};

const imageColor = {
  primary: "#FFF",
  secondary: "#1B2D93"
};

export const initiateCall = async (phoneNum) => {
  const granted = await PermissionsAndroid.request(
    PermissionsAndroid.PERMISSIONS.CALL_PHONE
  );

  if (granted === PermissionsAndroid.RESULTS.GRANTED) {
    NativeModules.UiUtilities.makePhoneCall(phoneNum);
  } else {
    Alert.alert(
      "Permission Required",
      "Allow Rocket to make and manage phone calls?",
      [
        {
          text: "Agree",
          onPress: () => Linking.openSettings()
        },
        {
          text: "Dismiss",
          style: "cancel"
        }
      ]
    );
  }
};

class SCallButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mobileVerified: null,
      callHrVariant: ""
    };
  }

  async componentDidMount() {
    const { callHrPOC, jobID } = this.props;

    const { mobileVerified, callHrVariant, openCallHr } = this.props;

    if (mobileVerified) {
      this.setState({
        mobileVerified
      });
    }

    if (
      openCallHr === jobID &&
      (mobileVerified === "true" || mobileVerified === true)
    ) {
      openURL(`tel:${callHrPOC.contact}`);
      removeDataFromStorage("openCallHr");
    }

    if (callHrVariant) {
      this.setState({
        callHrVariant
      });
    }
  }

  sendCallHrAction = async () => {
    const { jobID, sources, client } = this.props;

    const payload = {
      id: jobID,
      application_history_action_id: APPLICATION_HISTORY_ID.CALL_HR
    };

    if (sources) {
      payload.source_id = getSourceId(sources, button_type.CallHR);
    }

    if (pushUTMEvent) {
      payload.utm = pushUTMEvent();
    }

    const { data } = await clientHelper("mutate")(client)({
      mutation: SHORT_JOB_APPLY,
      variables: payload
    });

    if (data) {
      const applicationCount = await getTotalApplicationCount(client);
      const userID = getData(data, "mutate.candidate.user.id");
      const pricingPlanType = getData(
        data,
        "mutate.apply.job.pricing_plan_type"
      );
      sendEventToGTM("Application Submit Success", {
        CandidateID: getData(data, "mutate.apply.candidate.id"),
        UserID: userID,
        ApplicationCount: applicationCount,
        pricing_plan: pricingPlanType
      });
      pushClevertapProfile({
        Identity: getData(data, "mutate.candidate.user.id"),
        Application_Count: applicationCount
      });
      if (Platform.OS === "android") {
        triggerAdjustApplicationEvent(
          "UserID",
          userID,
          "App_ID",
          getData(data, "mutate.apply.id"),
          "pricing_plan",
          pricingPlanType
        );
        if (
          pricingPlanType === "POSTPAID" ||
          pricingPlanType === "PREPAID" ||
          pricingPlanType === "TEMP_STAFFING"
        ) {
          triggerAdjustTrustedJobAppEvent(
            "UserID",
            userID,
            "App_ID",
            getData(data, "mutate.apply.id"),
            "pricing_plan",
            pricingPlanType
          );
        }
        if (applicationCount === 1) {
          triggerAdjustOnApplicationCreationEvent("UserID", userID);
        }
      }
    }
  };

  handleCallHrClick = async (e) => {
    const { callHrPOC, pushCleverTap, jobID } = this.props;
    const { mobileVerified, callHrVariant } = this.state;

    if (Platform.OS === "web") {
      e.preventDefault();
      e.stopPropagation();
    }

    if (pushCleverTap) {
      pushCleverTap("CALL HR");
    }
    sendEventToGA("Call_HR", "Call_HR_click", `call_hr_${callHrVariant}`, {
      event_value: 1
    });
    if (callHrVariant === "A") {
      sendEventToGA("Call_HR", "Apply_click", `call_hr_${callHrVariant}`, {
        event_value: 1
      });
    }

    if (mobileVerified === "true" || mobileVerified === true) {
      this.sendCallHrAction();

      if (Platform.OS === "android") {
        initiateCall(`tel:${callHrPOC.contact}`);
      } else {
        openURL(`tel:${callHrPOC.contact}`);
      }
    } else {
      let currentURL = NavHistory.get();
      currentURL = currentURL[currentURL.length - 1];

      storeDataToStorage("openCallHr", jobID);

      navigate("verify", {
        page: "mobile",
        onlyVerify: true,
        goBackTo: JSON.stringify(currentURL)
      });
    }
  };

  handleButtonClick = (e) => {
    const { clevertapProps, onClick, jobCardVariant } = this.props;
    const { callHrVariant } = this.state;

    if (Platform.OS === "web") {
      e.preventDefault();
      e.stopPropagation();
    }

    const clevertap_props = { ...clevertapProps };

    if (jobCardVariant) {
      // to track job card variant in call hr click
      clevertap_props["Test case"] = `JC_${jobCardVariant}`;
    }
    pushClevertapEvent("Job Clicks", {
      ...clevertap_props,
      Type: "Call HR",
      Test_Case: callHrVariant
    });

    sendEventToGTM("btn-click-call-hr");
    sendEventToGA("Call_HR", "Call_HR_click", `call_hr_${callHrVariant}`, {
      event_value: 1
    });
    if (callHrVariant === "A") {
      sendEventToGA("Call_HR", "Apply_click", `call_hr_${callHrVariant}`, {
        event_value: 1
      });
    }

    onClick();
  };

  render() {
    const { buttonType, containerStyle, onClick, size, text, disabled } =
      this.props;

    const HRCallClick = onClick
      ? this.handleButtonClick
      : this.handleCallHrClick;

    return buttonType !== "icon" ? (
      <SButton
        nativeID="callHR"
        key="call-hr-button"
        type={buttonType}
        text={text}
        textProps={sizeProps[size].textProps}
        containerStyle={containerStyle}
        prefixIcon={CallHrPhone}
        prefixIconProps={{
          fill: imageColor[buttonType],
          height: sizeProps[size].imageHeight,
          width: sizeProps[size].imageWidth
        }}
        onClick={HRCallClick}
        disabled={disabled}
      />
    ) : (
      <SClickableArea touchableType="opacity" onClick={HRCallClick}>
        <View style={[styles.iconContainer, containerStyle]}>
          <S3Image
            path="RocketIntegration"
            name="callHR"
            islazyLoadRequired={false}
            style={{
              height: 42,
              width: 42
            }}
          />
        </View>
      </SClickableArea>
    );
  }
}

const styles = StyleSheet.create({
  iconContainer: {
    backgroundColor: Colors.Grey[1],
    borderRadius: 24,
    width: 42,
    height: 42,
    justifyContent: "center",
    alignItems: "center"
  }
});

export default SCallButton;
