import { mLog } from "../logger";

export const sendEventToGTM = (action, options) => {
  if (typeof window !== "undefined" && window.dataLayer) {
    const props = {
      event: action,
      ...options
    };
    mLog("Google Tag Manager Event - ", action, props);
    window["dataLayer"].push(props);
  }
};